<template>
    <div>
        <div id="pencils" v-if="showPencils">    
            <div id="game_drawer" > 
                <div id="pencil_holder"> 
                    <a @click="inspiration()"><img id="Inspiration" class="inspiration" src="/img/drawing/elements/pencils/inspiration.svg" width="70" height="124" alt="Inspiration" title="Inspiration"/></a>
                    <a @click="activeEraser()"><img id="Eraser" class="eraser" src="/img/drawing/elements/pencils/pencil_eraser.svg" width="70" height="124" alt="Eraser" title="Eraser"/></a>
                    <a v-for="pencil in pencils" :key="pencil.id"  @click="activePencil(pencil.color,pencil.type,pencil.name)">
                        <img v-if="pencil.name == 'Rainbow'" v-bind:id="pencil.name" class="pencil selected" v-bind:src="pencil.icon" :alt="pencil.name" :title="pencil.name" width="40" height="124"/>
                        <img v-else v-bind:id="pencil.name" class="pencil" v-bind:src="pencil.icon" :alt="pencil.name" :title="pencil.name" width="40" height="124"/>
                    </a>
                    <a @click="downloadPicture()"><img id="Download" class="downloadImg" src="/img/drawing/elements/pencils/download.svg" width="70" height="124" alt="Download" title="Download"/></a>
                    <a @click.prevent="newDrawing()"><img id="newDrawing" class="newDrawing" src="/img/drawing/elements/pencils/new_drawing.svg" width="70" height="124" alt="New Drawing" title="New Drawing"/></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pencilsdata from '@/data/en/drawing/pencils.json'

export default {
    name: "Pencildrawer",
    props: {
        currentColor: String
    },
    data(){
        return {
            pencils: Pencilsdata,
            showPencils: true,
            erasing: false
        }
    },
    methods: {
        activePencil(color,type,id){
            this.unsetPencils();
            document.getElementById(id).className = "pencil selected";
            let selection = {
                color: color,
                type: type
            };
            this.$emit('changePencil', selection);
            this.tracking({
                type: 'event',
                category: 'activity:play',
                action: 'color',
                label: id
            });              
        },
        activeEraser() {
            this.unsetPencils();
            document.getElementById("Eraser").className = "eraser selected";
            this.$emit('eraserOn');
            this.tracking({
                type: 'event',
                category: 'activity:play',
                action: 'eraser',
            });      
        },
        inspiration() {
            this.$emit('OpenInspiration');
        },
        newDrawing() {
            this.$emit('startNewDrawing');
        },
        downloadPicture() {
            this.$emit('downloadPicture');
        },
        unsetPencils() {
            let pencils = document.getElementsByClassName("pencil");
            pencils.forEach(pencil => {
                document.getElementById(pencil.id).className = "pencil";
            });
            document.getElementById("Eraser").className = "eraser";
        }

    }
}
</script>

<style lang="scss" scoped>
#game_drawer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 123px;
    margin: 0;
    overflow: hidden;
    z-index: 1000;
    .pencil, .eraser, .inspiration, .newDrawing {
        -webkit-user-drag: none; 
        -khtml-user-drag: none; 
        -moz-user-drag: none; 
        -o-user-drag: none; 
        user-drag: none; 
    }
    .pencil {
        position:relative;
        bottom: -20px;     
        padding: 0;  
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    .pencil:hover, .eraser:hover {
        bottom: -10px;     
    }
    .eraser {
        margin: 0 19px;
        position:relative;
        bottom: -20px;     
        padding: 0;  
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    .selected, .selected:hover {
        position:relative;
        bottom: 0px;
    }
    .inspiration {
        margin: 20px 0 0 10px;
        position:relative;
    }
    .downloadImg {
        margin: 20px 0 0 10px;
    }
    .newDrawing {
        margin: 20px 0 0 0;
    }    
    .inspiration:hover,.newDrawing:hover,.downloadImg:hover {
        position:relative;
        bottom: 10px;        
    }
    .newDrawing,.downloadImg {
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));        
    }
}
</style>
