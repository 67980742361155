<template>
    <div>
        <div class="cursor" id="cursor"></div>
        <div id="background_container">
            <canvas id="game_canvas"></canvas>
        </div>
        <Drawer
        :currentColor="currentColor"
        @changePencil="changePencil($event)"
        @eraserOn="eraserOn()"
        @OpenInspiration="openInspiration()"
        @startNewDrawing="triggerNewDrawingMenu()"
        @downloadPicture="triggerDownloadPicture()"
        />  
        <Inspiration
        :showInspiration="showInspiration"
        @CloseInspiration="closeInspiration()"
        />           
        <Backgrounds
        :showBackground="showNewDrawing"
        @closeShowBackgroundModal="closeNewDrawing()"
        @selectedNewDrawing="triggerNewDrawingChoice($event)"
        />
    </div>  
</template>

<script>
import { mapState } from 'vuex';
import Drawer from '@/pages/play/drawing/components/drawer.vue'
import Inspiration from '@/pages/play/drawing/components/inspiration.vue'
import Backgrounds from '@/pages/play/drawing/components/backgrounds.vue' 
import { Drawing } from '@/pages/play/drawing/lib/drawing'
//let autoSaving = {};
export default {
    name: "Drawing", 
    props: {
        closeGameModals: Boolean,
        pusherActive: Boolean
    },
    components: {
        Drawer,
        Inspiration,
        Backgrounds
    },        
    computed: {
        ...mapState(['activityCache']),  
        ...mapState(['activeProfile']),
        ...mapState(['loadedSave']), 
        ...mapState(['players']), 
        ...mapState(['saves']),  
        roomIdRoute: function() {
            return this.$route.params.space_id;
        },
        channelName: function () {
            let c = 'presence-playspaces-' + this.$route.params.space_id;
            return c;
        }
    },    
    data: function() {
        return {
            drawingApp: null,
            showInspiration: false,
            showNewDrawing: false,
            currentColor: '#000000',
            saveId: null,
            pusher: null,
            channel: null,
            hasMounted: false,
            autoSaving: null
        }
    },
    watch: {
        'closeGameModals': {
            handler (newVal) {
                if (newVal) { 
                    if(this.closeGameModals === true){
                        this.closeNewDrawing();
                        this.$emit('resetClose');
                    }
                }
            },
            immediate: true 
        },        
        'loadedSave': {
            handler (newVal) {
                if(this.hasMounted) {
                    this.updateLoad() 
                }
                  
            },
            immediate: true     
        }                  
    },        
    mounted() {
        this.hasMounted = true;
        this.initDrawing();
        window.addEventListener('beforeunload', async (e) => { 
            clearInterval(this.timer); 
        });         
    },
    ready() {
        
    },
    methods: {
        async pusherBind(){
            this.channel = await this.$pusher.subscribe('presence-playspaces-' + this.$route.params.space_id);       
            this.channel.bind("pusher:member_added", (member) => {        
                console.log("NEW MEMBER: " + JSON.stringify(member.info));
                let bg = this.drawingApp.currentBackground;
                if(this.activityCache !== null){
                    this.drawingApp.transferHistory(this.activityCache.content);
                } else if(this.showNewDrawing === false){
                    this.channel.trigger('client-setbackground', bg);
                }
            });         
            this.channel.bind('client-newdrawing', async (b) => {
                this.closeNewDrawing();
                this.selectedNewDrawing(b);
            });
            this.channel.bind('client-history-transfer', async (b) => {
                //this.closeNewDrawing();
                //this.selectedNewDrawing(b);
            });            
            this.channel.bind('client-new-drawing', (data) => {
                this.makeToast(data.message);
            });
            this.channel.bind('client-setbackground', (data) => {
                this.closeNewDrawing();
                this.drawingApp.changeBackground(data);
            });            
            this.channel.bind('client-host-picking', (data) => {
                this.makeToast(data.message);
            });                             
            this.channel.bind('client-guest-picking', (data) => {
                this.makeToast(data.message);
            });
            this.channel.bind("pusher:subscription_error", (error) => {
                //this.$sentry.captureException(error);
            });
        },
        async initDrawing(){
            function preventBehavior(e) {
                e.preventDefault(); 
            }
            this.username = this.activeProfile.name;
            this.backgroundContainer = document.getElementById("background_container"); //background
            this.gameCanvas = document.getElementById("game_canvas");
            await this.pusherBind();
            this.drawingApp = new Drawing(this.gameCanvas, this.backgroundContainer, this.activeProfile.name, this.$pusher, this.channel);
            this.drawingApp.init();
            this.gameCanvas.addEventListener("touchmove", preventBehavior, {passive: false});  
            if(this.loadedSave === null && this.saveId === null) {
                if(this.activityCache !== null) {
                    this.loadCache();
                } else {
                    this.autoSaveOff();
                    this.openNewDrawing();
                    this.autoSave();
                }                    
            } else {
                if(this.loadedSave !== null) {
                    if(this.saveId !== this.loadedSave){
                        this.loadSave(this.loadedSave);  
                    }
                    this.saveId = this.loadedSave;   
                } else {
                    this.loadCache();                        
                }
            }
            this.tracking({
                type: 'page',
                title: '/playspace/drawingapp',
            });  
            window.addEventListener('beforeunload', async (e) => { 
                this.autoSaveOff();
            });  
        },
        changePencil(selection){
            this.drawingApp.setPencil(selection.color,selection.type)
        },
        eraserOn(){
            this.drawingApp.setEraser();
        },
        openInspiration() {
            this.showInspiration = true;
            this.showInspiration = true;
            this.$emit('closeModals');
        },
        closeInspiration() {
            this.showInspiration = false;
        },
        autoSave(){
            console.log("auto save on");
            this.autoSaving = setInterval(() => {this.saveData()}, 2000);
        },  
        autoSaveOff(){
            console.log("auto save off");
            clearInterval(this.autoSaving);
        },         
        async loadCache(){
            this.autoSaveOff();
            this.drawingApp.actionClearCanvas();
            if(this.activityCache !== null) {
                this.drawingApp.loadDrawing(this.activityCache.content);
            }
            this.autoSave();
            this.closeNewDrawing();
        },
        async loadSave(saveId) {
            await this.$store.dispatch('getActivityData');
            this.autoSaveOff(); 
            await this.$store.commit('setActivityCache', null); 
            let loadSave = this.saves.saves.find(s => s.id === saveId);
            this.drawingApp.actionClearCanvas();
            if(loadSave?.content !== null) {
                this.drawingApp.loadDrawing(JSON.parse(loadSave.content));
            }
            this.autoSave();
            this.closeNewDrawing();
        },
        async saveData() {
            let name = this.activeProfile.name + "'s " + this.$store.getters.currentActivity;
            let payload = {
                saveId: this.$store.getters.loadedSave,
                name: name,
                players: this.players || null ,
                assetPath: null,
                thumbPath: null,
                content: this.drawingApp.drawingHistory,
                activityName: this.$store.getters.currentActivity,
            };            
            //This stuffs the drawing in local storage.
            if(this.drawingApp.drawingHistory.segments.length > 0) {
                console.log("Saving game cache");
                await this.$store.commit('setActivityCache', payload);
                this.createImage();
            }
        },  
        async generatePNG(width,height){
            try {
                var background = new Image();
                background.src = this.drawingApp.drawingHistory.background.assetUrl;
                var resizedCanvas = document.createElement("canvas");
                var resizedContext = resizedCanvas.getContext("2d");
                resizedCanvas.height = height;
                resizedCanvas.width = width;
                var canvas = document.getElementById("game_canvas");
                resizedContext.drawImage(background,0,0, width, height);
                resizedContext.drawImage(canvas, 0, 0, width, height);
                var myResizedData = resizedCanvas.toDataURL("image/png");       
                return myResizedData;
            } catch (error) {
                return error;
            }
        },        
        async createImage(){
            let m = await this.generatePNG(360, 260);
            this.$store.commit('setActivityThumb', m);
            //var w = window.open('about:blank','image from canvas');
            //w.document.write("<img src='"+m+"' alt='from canvas'/>");
        },   
        triggerNewDrawingMenu() {     
            console.log("Host Open Drawing Menu");
            if(this.activeProfile.host === true) {
                this.channel.trigger('client-host-picking', {message: this.$t('toasts.client-host-picking')});    
            } else {
                this.channel.trigger('client-guest-picking', {message: this.$t('toasts.client-guest-picking')});
            }
            this.openNewDrawing()  
        },
        triggerNewDrawingChoice(path){
            if(this.activeProfile.host === true) {
                this.channel.trigger('client-new-drawing', {message: this.$t('toasts.client-new-drawing')});  
                this.channel.trigger('client-newdrawing', path);
            }
            this.selectedNewDrawing(path);
        },  
        async triggerDownloadPicture(){
            let m = await this.generatePNG(1024,768);
            var w = window.open('about:blank','Your drawing');
            w.document.write("<img src='"+m+"' alt='Your drawing'/>");
            this.tracking({
                type: 'event',
                category: 'activity:play',
                action: 'downloaddrawing'
            }); 
        },       
        openNewDrawing() {
            this.showNewDrawing = true;
            this.tracking({
                type: 'event',
                category: 'activity:play',
                action: 'newdrawing'
            });    
        },
        closeNewDrawing() {
            this.showNewDrawing = false;
        },
        async selectedNewDrawing(path){
            this.autoSaveOff();
            if(this.$store.getters.activityCache !== null) {
                await this.$store.dispatch('saveActivity')
                .then(() => {
                    this.startNewDrawing(path);
                });
            } else {
                this.$store.commit('setActivityCache', null);
                this.startNewDrawing(path);
            }

        },
        startNewDrawing(path) {
            this.saveId = null;         
            this.drawingApp.actionClearCanvas();             
            this.$store.dispatch('updateRoomLoadedSave', null);
            this.$store.commit('setLoadedSave', null); 
            this.drawingApp.changeBackground(path);
            this.autoSave();
        },
        updateLoad() {
            if(this.loadedSave !== null){
                if(this.saveId !== this.loadedSave){
                    this.loadSave(this.loadedSave);  
                }
                this.saveId = this.loadedSave
            }            
        }   
    },
    beforeDestroy() {
        this.autoSaveOff();
    }
};
</script>

<style lang="scss" scoped>
html {
    height  : 100%;
    overflow: hidden;
    position: relative;
}
body {
    height  : 100%;
    overflow: auto;
    position: relative;
}
#game_canvas {
    cursor: none;
}
.cursor {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 3px solid #000;
    pointer-events: none;
    user-select: none;
    //mix-blend-mode: difference;
    //opacity: 0;
    //transition: opacity 1s;
    z-index: 9000;
}   
#background_container {
    //drawing area background  
    background: #FFF;
    background-size: cover;                     
    background-repeat: no-repeat;
    background-position: top center;  
    overflow: hidden;
    width: 1024px;
    height: 768px;
    position: inherit;
}
</style>