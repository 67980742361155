<template>
    <div id="inspiration-modal-overlay" v-if="showInspiration == true">
        <div id="inpsiration-modal">
            <div id="invitemodal-close">
                <a @click.prevent="closeInspiration()"><img src="/img/btns/close.svg" width="22" height="22" alt="Close Button" title="Close Button"/></a>
            </div>                  
            <img :src="inspiration.assetUrl" width="280" height="180"/>
            <br/><br/>
            <h2>{{inspiration.heading}}</h2>
            <br/>
            <div class="circle-btn" @click="getInspiration()">
                <img src="/img/btns/arrow.svg" width="50" height="50" />
            </div>
        </div>
    </div>
</template>

<script>
import InspirationData from '@/data/en/drawing/inspirations.json'
export default {
    name: "Inspiration",
    props: {
        showInspiration: Boolean
    },
    watch: {
      'showInspiration': {
        handler (newVal) {
            if (newVal == true) { 
                this.getInspiration();
            }
        },
        immediate: true 
      }
    },    
    data(){
        return {
            inspirationData: InspirationData,
            inspiration: {
                heading: "",
                assetUrl: ""
            }
        }
    },
    mounted() {
        //this.getInspiration();
    },
    methods: {
      getInspiration(){
        let randomItem = Math.floor(Math.random() * Math.floor(10));       
        this.inspiration.heading = this.inspirationData[randomItem].heading;
        this.inspiration.assetUrl = this.inspirationData[randomItem].assetUrl;
        this.tracking({
            type: 'event',
            category: 'activity:play',
            action: 'inspiration',
            label: this.inspirationData[randomItem].name
        }); 
      },
      closeInspiration(){
        this.$emit('CloseInspiration');
      }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#invitemodal-close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 2200;
}
#inspiration-modal-overlay{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2100;
    width: 100%;
    height: 100%;
    background: rgba(38, 32, 114, 0.5);
}
.circle-btn {
    width: 80px;
    position: absolute;
    bottom: 20px;
    left: 155px;
    height: 80px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 auto;
    text-align: center;
    img {
    text-align: center;
    margin: 15px auto 0 auto;
    } 
    background: $ps-purple;
}
#inpsiration-modal {
    position: relative;
    top: 50%;
    transform: translateY(-50%);      
    padding: 20px 40px;
    background: $ps-light-purple;
    border-radius: 30px; 
    width: 390px;
    min-height: 390px;
    text-align: center;
    margin: 0 auto;  
}
</style>
