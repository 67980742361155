<template>
    <div>
        <Largemodal
        :largeModalShow="showBackground"
        @closeLargeModal="closeShowBackgroundModal()"
        @scrollingActive="scrollingOn($event)"
        >
        <img src="/img/drawing/elements/drawing_alien.svg" width="337" height="380" class="d-inline-block align-top"/>
        <div id="backgrounds" class="d-inline-block align-top">    
            <div class="backgrounds-list d-inline-block align-top">  
                <div v-for="background in backgrounds" 
                :key="background.id" 
                class="drawing-background d-inline-block align-top">
                    <img v-bind:src="background.thumbUrl" 
                    v-on:click.self="selectedNewDrawing(background.assetUrl,background.name)"
                    width="360"
                    height="270"
                    />
                    <div class="background-description">{{background.description}} </div>
                </div>
            </div>
        </div>
    
        </Largemodal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Largemodal from '@/components/modals/largemodal.vue'
import Backgroundsdata from '@/data/en/drawing/backgrounds.json'

export default {
    name: "Drawingbackgrounds",
    components:{
        Largemodal,
    },
    props: {
        showBackground: Boolean
    },
    watch: {
        'showBackground': {
            handler (newVal) {
                if (newVal && this.showBackground == true) { 
                    this.tracking({
                        type: 'event',
                        category: 'activity:play',
                        action: 'modal:view'
                    });
                }
            },
            immediate: true 
        }
    },   
    computed: {
        ...mapState(['activeProfile'])
    },        
    data(){
        return {
            backgrounds: Backgroundsdata,
            scrolling: false,
            bg: null
        }
    },   
    methods: {
        selectedNewDrawing(url, name){
            if(this.$store.getters.isHost){
                this.$emit('selectedNewDrawing',url);
                this.closeShowBackgroundModal(); 
                this.tracking({
                    type: 'event',
                    category: 'activity:play',
                    action: 'modal:select',
                    label: name
                }); 
            }
        },
        closeShowBackgroundModal(){
            this.$emit('closeShowBackgroundModal');            
        },
        scrollingOn(e){

        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
    #backgrounds {
        .backgrounds-list {
            height: 270px;
            margin: 50px 0 0 0;      
        }
        .drawing-background {
            margin: 20px;
            img {
                cursor: pointer;
            }
        }
        .background-description {
            font-weight: $ps-bolder-weight;
            padding: 15px 0;
            font-size: $h3-size;
        }
        img {
            max-height: 270px;
            max-width: 360px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
        }
    }
</style>