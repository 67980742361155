import Pencilsdata from '@/data/en/drawing/pencils.json'
export default class Drawing {
    constructor (canvas, background, username, pusher, channel)
    {        
        this.username = username;
        this.pusher = pusher;
        this.channel = channel;
        this.count = 0;
        this.painting = false;
        this.moveCount = 0;
        this.mouseSampleRate = 3; // skip every other
        this.mouseX = 0;
        this.mouseY = 0;
        this.tempHistory = []; //Is this used for anything??
        this.runRedraw = false;
        this.drawingHistory = {
            background: {
                name: null,
                assetUrl: null
            },
            segments: []
        };
        this.recentSegment = [];
        this.remoteSegment = [];
        this.currentMode = 'rainbow'
        this.brushSize = 6;
        this.brushSizes = [ 3, 6, 9, 12, 20 ];
        this.currentColor = '#000000';
        this.cursorColor = '#000000'; 
        this.rainbowCounter = 1;      
        this.currentBackground = "/img/drawing/backdrop/backdrop1.jpg";
        this.pencils = Pencilsdata;
        this.gameCanvas = canvas;
        this.backgroundContainer = background;
        this.rect = this.gameCanvas.getBoundingClientRect();
        this.ctx = this.gameCanvas.getContext("2d"); 
    }

    init() {
        let this_ = this;
        window.addEventListener("touchstart", this_.touchHandler, false);       
        //Pointer (touch/mouse) event binding
        this_.gameCanvas.addEventListener('pointerdown', (e)=>{ 
            this_.painting = true;
            this_.recentSegment = [];
            //TODO: refine history json structure to be consistent with the stub for saved game data
            //this_.recentSegment.dateTime = new Date(Date.UTC());
            //this_.recentSegment.userName = this_.username;
            //this_.recentSegment.points = [];
            this_.mouseX = e.offsetX;//clientX
            this_.mouseY = e.offsetY;
            this_.ctx.beginPath();
        });
        this_.gameCanvas.addEventListener('pointerup', ()=>{ 
            this_.endSegment();
            if(this_.runRedraw === true) {
                this_.redraw();
                this_.runRedraw = false;
            }
        }); 
        this_.gameCanvas.addEventListener('pointerleave', ()=>{ 
            this_.endSegment();
        });  
        this_.gameCanvas.addEventListener('pointermove', (e)=>{ 
            this_.moveMouse(e);
            if(this_.painting) {
				this_.mouseX = e.offsetX;
                this_.mouseY = e.offsetY;
                let linePoint = {
                    x: this_.mouseX,
                    y: this_.mouseY,
                    c: this_.currentColor,
                    r: this_.brushSize,
                    t: this_.currentMode
                };
                if(this_.moveCount === this_.mouseSampleRate) {
                    this_.moveCount = 0;
                    
                } else {
                    this_.recentSegment.push(linePoint); 
                    this_.drawPoint(linePoint);
                    this_.moveCount++;
                }

            }
        });  
        this_.pusherDrawStart();

        //setup                                 
        window.addEventListener('resize', ()=>{
            this_.setCanvasSize();
            this_.setBackground(this_.currentBackground);
        });      

        this_.setCanvasSize();
        this_.setBackground(this_.currentBackground);       
    }
    pusherDrawStart(){
        let this_ = this;
        // Pusher event bindings
        this_.channel.bind('client-draw', (data) => {
            let vueThis = this;
            //console.log(JSON.stringify(data));
            if(data.segment) {
            data.segment.forEach(function(point){
                vueThis.remoteSegment.push(point);
            });
            if(data.fullLength == vueThis.remoteSegment.length) {
                vueThis.drawingHistory.segments.push(vueThis.remoteSegment);
                // wait until they are done drawing to redraw
                if(!this_.painting){
                vueThis.redraw();
                }
                
                vueThis.remoteSegment = [];
            }
            vueThis.runRedraw = true;
            }
        });  
    }
    touchHandler(event){
        /*if(event.touches.length > 1){
            event.preventDefault()
        }*/
    }
    transferHistory(drawingHist) {
        //console.log("pushing history to guest")
        let this_ = this;
        let i,j;
        // Pusher limits dat to 10240 bytes each point is about 66 bytes so we are chunking at 100 points.
        let tempArray = [];
        let payload = [];
        let chunk = 100;
        let bg = drawingHist.background.assetUrl;
        this_.channel.trigger('client-setbackground', bg);
        drawingHist.segments.forEach(segment => {
            let l = segment.length;
            for (i=0,j=l; i<j; i+=chunk) {
                tempArray = segment.slice(i,i+chunk);
                let p = { segment: tempArray, fullLength: l };
                this_.channel.trigger('client-draw', p);
                //this_.channel.trigger('client-history-transfer', p);
                //let s = {channel: 'my-channel-1', name: 'client-draw', data: p}
                //payload.push(s);
            }    
        })
        // ! this doesn't work yet
        // this_.pusher.trigger_batch(payload); 
    }
    endSegment(){
        let this_ = this;
        this_.painting = false;
        if(this_.recentSegment.length === 0){
            console.log("nothing in segment"); 
        } else {
            //console.log("pushing segment: "+ JSON.stringify(this_.recentSegment));
            this_.drawingHistory.segments.push(this_.recentSegment);
            //
        }
        let i,j;
        // Pusher limits dat to 10240 bytes each point is about 66 bytes so we are chunking at 100 points.
        let tempArray = [];
        let chunk = 100;
        let l = this_.recentSegment.length;
        for (i=0,j=l; i<j; i+=chunk) {
            tempArray = this_.recentSegment.slice(i,i+chunk);
            let p = { segment: tempArray, fullLength: l };
            this_.channel.trigger('client-draw', p);
            //console.log("syncing to other player segment: " + JSON.stringify(p));
        }
        //pusher.triggerBatch(events); REFACTOR TO USE BATCH?
        this_.recentSegment = []; 
    }

    setCanvasSize(){
        //let rect = this.gameCanvas.getBoundingClientRect();
        let this_ = this;
        this_.gameCanvas.height = 768;//window.innerHeight-this.rect.top
        this_.gameCanvas.width = 1024;//window.innerWidth-this.rect.left
    }

    changeBackground(bg){
        let this_ = this;
        this_.setBackground(bg);
    }

    setBackground(bg){
        let this_ = this;
        let background = new Image();
        this_.currentBackground = bg;
        this_.drawingHistory.background.assetUrl = this_.currentBackground;
        background.src = this_.currentBackground;
        this_.backgroundContainer.style.backgroundImage = "url('"+this_.currentBackground+"')";
        this_.redraw();     
    }

    setColor(color){
        let this_ = this;
        this_.currentColor = color;
        this_.cursorColor = color;
    }

    setEraser(){
        let this_ = this;
        this_.erasing = true;
        this_.setMode('eraser');
        this_.cursorColor = "#CDCFFF";
    }

    setMode(type){
        let this_ = this;
        this_.currentMode = type;        
    }

    setPencil(color,type){
        let this_ = this;
        this_.erasing = false;
        if(color){
            this_.setColor(color);
        } else {
            this_.setColor(this_.currentColor);
        }
        this_.setMode(type);
    }
    
    moveMouse(e){
        let x = e.offsetX;//e.offsetX
        let y = e.offsetY;//e.offsetY
        var cursor = document.getElementById('cursor');
        cursor.style.border = "3px solid " + this.cursorColor;
        cursor.style.transform = `translate(${x - 5}px, ${y - 5}px)`;
    }

    randColor(){
        let this_ = this;
        //unused for now
        let randomColor = Math.floor(Math.random() * Math.floor(17)); //+1
        let r = this_.pencils[randomColor].color;
        return r;
    }

    rainBowColors (){
        //cycles through colors 1 to 13, skips the rainbow and the darker ones.
        let this_ = this;
        let c = this_.pencils[this_.rainbowCounter].color;
        if(this_.rainbowCounter >= 13 || this_.rainbowCounter <= 3 )
        {
            this_.rainbowCounter = 4;
        } else {
            this_.rainbowCounter = this_.rainbowCounter+1;
        }
        return c;
    }

    drawPoint(linePoint) {
        let this_ = this;
        this_.ctx.lineCap = "round";  
        this_.ctx.lineJoin = "round";   
        if(linePoint.t == 'rainbow'){
            if(linePoint.c == '#000000' ) {
                let rColor = this_.rainBowColors();
                linePoint.c = rColor;
                this_.cursorColor = rColor;
            }
        }
        this_.ctx.strokeStyle = linePoint.c;
        if(linePoint.t == 'eraser') { //if erasing point true linePoint.d
            this_.ctx.lineWidth = 20;
            this_.ctx.globalCompositeOperation="destination-out";
        } else {       
            this_.ctx.lineWidth = linePoint.r;
            this_.ctx.globalCompositeOperation="source-over";
        }     
        let rect = this_.gameCanvas.getBoundingClientRect();
        this_.ctx.lineTo(linePoint.x  ,linePoint.y  ); //TODO - this.rect.left - this.rect.top adjust the offset programmatically
        this_.ctx.stroke(); 
        this_.ctx.beginPath();
        this_.ctx.moveTo(linePoint.x ,linePoint.y ,);    //- this.rect.top)- this.rect.left,
    }

    actionClearCanvas(){
        let this_ = this;
        // This is for the button click which needs to be 
        // separate from the pusher call or they will loop forever.
        this_.clearCanvas();
    }

    clearCanvas(){
        let this_ = this;
        this_.drawingHistory.background.name = null;
        this_.drawingHistory.background.assetUrl = null;
        this_.drawingHistory.segments = [];
        this_.recentSegment = [];
        this_.ctx.clearRect(0, 0, this_.gameCanvas.width , this_.gameCanvas.height);
        this_.setBackground(this_.currentBackground);
    }

    undo() {
        let this_ = this;
        this_.drawingHistory.segments.pop();// TODO this needs to be fixed
        this_.drawingHistory.segments.pop();
        this_.recentSegment = [];
        this_.ctx.clearRect(0, 0, this_.gameCanvas.width , this_.gameCanvas.height);
        this_.setBackground(this_.currentBackground);
        this_.redraw();
    }

    redraw(){
        let this_ = this;
        
        if(!this_.drawingHistory.segments.length){
            return true;
        } else {
            this_.drawingHistory.segments.forEach((segment)=>{
                this_.ctx.beginPath();
                segment.forEach((point)=>{
                    this_.drawPoint(point);
                });
                
            }); 
        }
       
    }   
    
    async loadDrawing(savedData){
        //TODO: load saved drawing
        let this_ = this;
        this_.clearCanvas();
        this_.drawingHistory = savedData;
        this_.setBackground(this_.drawingHistory.background.assetUrl);
        this_.redraw();
    }

}

export { Drawing };